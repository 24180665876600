import React from 'react'

type Props = {
}

const Component: React.FC<Props> = props => {
  return (
    <div style={{ maxWidth: 1180, margin: '0 auto' }}>{props.children}</div>
  )
}

export default Component