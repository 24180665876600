import React from 'react'
import { Link } from 'gatsby'
const styles = require('./article-preview.module.css')

type Props = {
  tag: string
}

const Component: React.FC<Props> = props => {
  return (
    <p className={styles.tag} key={props.tag}>
      <Link to={`/tag/${props.tag}`}>{props.tag}</Link>
    </p>
  )
}

export default Component