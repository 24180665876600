import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Tag from './tag'
import { ContentfulBlogPost, ContentfulFluid, MarkdownRemark } from '../../types/graphql-types';

const styles = require('./article-preview.module.css')

type Props = {
  heroImage: ContentfulFluid
  slug: string
  title: string
  publishDate: string
  description: string
  tags: Array<string>
}

const Component: React.FC<Props> = props => {
  return (
    <div className={styles.preview}>
      <Img alt="" fluid={props.heroImage} />
      <h3 className={styles.previewTitle}>
        <Link to={`/blog/${props.slug}`}>{props.title}</Link>
      </h3>
      <small>{props.publishDate}</small>
      <div
        dangerouslySetInnerHTML={{
          __html: props.description,
        }}
      />
      {props.tags &&
        props.tags.map(tag => (
          <Tag tag={tag} />
        ))}
    </div>
  )
}

export default Component
