import React from 'react'
import { ContentfulAsset, ContentfulFluid } from "../../types/graphql-types";
import Img from 'gatsby-image'

const styles = require('./hero.module.css')

type Props = {
  name: string
  title: string
  shortBio: string
  heroImage: ContentfulFluid
}

const Component: React.FC<Props> = props => {
  return (
    <div className={styles.hero}>
    <Img
      className={styles.heroImage}
      alt={props.name}
      fluid={props.heroImage}
    />
    <div className={styles.heroDetails}>
      <h3 className={styles.heroHeadline}>{props.name}</h3>
      <p className={styles.heroTitle}>{props.title}</p>
      <p>{props.shortBio}</p>
    </div>
  </div>
  )
}

export default Component