import React from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet'
import { HomeQuery } from '../../types/graphql-types';
import ArticlePreview from '../components/article-preview'
import Layout from '../components/layout'
import Hero from '../components/hero'

type Props = {
  data: HomeQuery
}

const Component: React.FC<Props> = ({ data }) => {
  const location = useLocation();
  const person = data.allContentfulPerson.edges[0].node

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Helmet title={data.site.siteMetadata.title} />
        <Hero
          name={person.name}
          title={person.title}
          heroImage={person.heroImage.fluid}
          shortBio={person.shortBio.shortBio}
        />
        <div className="wrapper">
          <h2 className="section-headline">Recent articles</h2>
          <ul className="article-list">
            {data.allContentfulBlogPost.edges.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview
                    heroImage={node.heroImage.fluid}
                    slug={node.slug}
                    title={node.title}
                    description={node.description.childMarkdownRemark.html}
                    publishDate={node.publishDate}
                    tags={node.tags}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default Component

export const pageQuery = graphql`
  query Home {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

