import React from 'react'
import { Link } from 'gatsby'
import Container from './container'
import Navigation from './navigation'
import { useLocation, WindowLocation } from '../../../gatsby-contentful-starter/node_modules/@types/reach__router';

type Props = {
  location: WindowLocation
}

const base = require('./base.css')

const Template: React.FC<Props> = props => {

  let header

  let rootPath = `/`
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`
  }

  return (
    <Container>
      <Navigation />
      {props.children}
    </Container>
  )
}

export default Template
